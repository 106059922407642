import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const Wrapper = styled.div`
  background: ${Theme.colors.pearlGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;

  @media screen and (max-width: 767.98px) {
    flex-direction: column;
    padding-bottom: 12px;
  }
`

export const Text = styled.a`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
  text-align: center;
  color: ${Theme.colors.primary};
  padding: 10px;
  margin-bottom: 0;
  text-decoration: underline;

  @media (max-width: 768px) {
    width: 500px;
    max-width: 100%;
  }
`
