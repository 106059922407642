import React from 'react'
import { Wrapper, Text } from './styles'

const SHOW_BANNER = false

export default () => (
  SHOW_BANNER
    ? (
        <Wrapper>
          <Text href='/my-membership/pga-annual-meeting/annual-meeting-livestream'>View the 106th PGA Annual Meeting Livestream Here</Text>
        </Wrapper>
      )
    : null
)
