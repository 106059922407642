import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Theme, SecondaryNav, IframeRolex } from '@pga/pga-component-library'

const NavFrame = styled.div`
  z-index: 5;
  line-height: 1;
  @media (min-width: 992px) {
    padding-bottom: 30px;
  }
`

const NavFrameFixed = styled.div`
  z-index: 5;
  line-height: 1;
  @media (min-width: 992px) {
    position: absolute;
    left: 0;
    right: 0;
  }
`

export const CompLink = styled(Link)`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  font-weight: 500;
  color: ${Theme.colors.primaryWithOpacity};
  padding-right: 50px;
  &.active {
    text-decoration: none;
    color: ${Theme.colors.primaryWithOpacity};
    font-weight: 900;
  }
  @media (max-width: 991px) {
    color: ${Theme.colors.primary};
    font-size: 13px;
    line-height: 24px;
    display: block;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    background: transparent;
    font-weight: bold;
    &.active {
      padding-left: 10px;
      border-left: 5px solid ${Theme.colors.gold};
      color: ${Theme.colors.primary};
      text-decoration: none;
    }
  }  
`

const secondaryNavQuery = graphql`
  {
    allNavigationSecondaryYaml {
      edges {
        node {
          id
          label
          to
        }
      }
    }
  }
`
const isSearchActive = (location, node) =>
  (location.pathname === '/' && node.label === 'Search') ||
  (location.pathname === '/search' && node.label === 'Search')

const getActiveClassName = (location, node) => {
  if (!location.pathname) return ''
  if (isSearchActive(location, node)) return 'active'
  return location.pathname.includes(node.to) && node.to !== '/' ? 'active' : ''
}

const setActive = location => ({ node }) => ({
  ...node,
  classNames: getActiveClassName(location, node)
})

export default ({ location = {}, subNavFixed }) => {
  const { allNavigationSecondaryYaml } = useStaticQuery(secondaryNavQuery)
  const options = allNavigationSecondaryYaml.edges.map(setActive(location))
  const NavWrapper = subNavFixed ? NavFrameFixed : NavFrame
  return (
    <NavWrapper>
      <SecondaryNav options={options} title='Resources' menuTitle='Resources Menu' CompLink={CompLink} />
      <IframeRolex mobile />
    </NavWrapper>
  )
}
